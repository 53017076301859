<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <v-icon>layers</v-icon>
            Income / Expense Report
            <v-spacer></v-spacer>
            <add-button
              v-if="download_url"
              :circular="false"
              icon="print"
              :permission="'section-read'"
              @action="printReport"
              >Print
            </add-button>
          </v-card-title>

          <v-card flat>
            <v-card-title class="title" style="margin-top: -20px">
              <v-flex xs4 sm4>
                <v-text-field
                  label="From Date"
                  v-model="frmDate"
                  prepend-icon="event"
                  placeholder="YYYY-MM-DD"
                  :height="25"
                />
              </v-flex>
              <v-flex xs4 sm4>
                <v-text-field
                  label="To date"
                  v-model="toDate"
                  prepend-icon="event"
                  placeholder="YYYY-MM-DD"
                  :height="25"
                />
              </v-flex>
              <v-flex xs4 sm4>
                <add-button
                  icon="search"
                  :permission="'section-read'"
                  @action="get"
                  >Search
                </add-button>
              </v-flex>
            </v-card-title>
          </v-card>
        </v-card>

        <v-data-table
          :loading="form.loading"
          :headers="headers"
          :items="form.items.data"
          class="elevation-1"
          :hide-default-footer="true"
          hide-actions
          :rows-per-page-items="rowsPerPageItems"
          :pagination.sync="pagination"
          :total-items="form.items.meta.total"
        >
          <template slot="items" slot-scope="{ item }">
            <td>{{ item.voucher_no }}</td>
            <td>{{ item.entry_date }}</td>

            <td class="text-xs-left">{{ item.ledger_head }}</td>

            <td class="text-xs-right">
              <strong>{{ item.dr || "-" }}</strong>
            </td>

            <td class="text-xs-right">
              <strong>{{ item.cr || "-" }}</strong>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Form from "@/library/Form";
const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();

export default {
  name: "JournalList",
  // components: {Spinner,Notification},
  data: () => ({
    frmDate: nd.format("YYYY-MM-DD"),
    toDate: nd.format("YYYY-MM-DD"),
    form: new Form({}, "/api/journal-entry/income-expense/report"),
    download_url: "",
    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
    },

    headers: [
      {
        text: " V. No./Type ",
        value: "serial_no",
        align: "left",
        sortable: false,
        width: 100,
      },
      {
        text: "Date",
        value: "nepali_date",
        align: "left",
        sortable: false,
        width: 120,
      },
      {
        text: "Account Head",
        value: "account_head",
        align: "left",
        sortable: false,
      },
      { text: "Payment", value: "payment", align: "right", sortable: false },
      { text: "Receipt", value: "receipt", align: "right", sortable: false },
    ],
  }),
  mounted() {},
  computed: {},
  methods: {
    get() {
      this.form
        .get(null, `?frmDate=${this.frmDate}&toDate=${this.toDate}`)
        .then(({ data }) => {
          console.log(data);
          this.download_url = data.download_link;
        });
    },

    printReport() {
      window.open(this.download_url, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped></style>
